import React, { useState, useEffect, useRef } from 'react';
import Swal from 'sweetalert2';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import axios from "axios";
import withReactContent from 'sweetalert2-react-content'
function Expenses() {
  const MySwal = withReactContent(Swal)
  // const uploadUrl = "http://localhost:9650/public/receipt/";
  const uploadUrl = "https://cci.starlightsoftware.co.bw/public/receipt/";
  //const url = "http://localhost:9650/api"
  const url = "https://cci.starlightsoftware.co.bw/api"
  const [expenses, setexpenses] = useState([]);
  const fileInputRef = useRef(null);
  const [state, setState] = useState({
    "date": '',
    "amount": '',
    "totalUsers": ""
  })
  useEffect(() => {
    axios.get(`${url}/expense`, {}).then((res) => {
      setexpenses(res.data.data);
    }).catch((err) => console.log(err))
  }, [])

  const handleChange = (e) => {
    const { id, value } = e.target
    setState(prevState => ({
      ...prevState,
      [id]: value
    }))
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const update = async () => {
    try {
      MySwal.fire({
        didOpen: async () => {
          MySwal.showLoading();
          if (!state.amount || !state.date || !state.totalUsers) {
            MySwal.fire({
              title: <p>Please fill all fields</p>,
              didOpen: () => { },
            });
            return
          }
          const post = await axios.post(`${url}/expense`, state, {});
          //console.log(post.data.data)
          setexpenses(post.data.data);
          MySwal.fire({
            title: <p>Item saved</p>,
            didOpen: () => { },
          });
        },
      }).then(() => {
        // Swal.close()
      });
    } catch (error) {
      await MySwal.fire({
        title: <p>An error occured</p>,
        didOpen: () => { },
      });
    }
  }

  //use this to process single image pick here (non native)
  const processSingleImage = async ($event) => {
    return new Promise((resolve, reject) => {
      try {
        let file = $event.target.files[0] ?? $event;
        const data = new FileReader();
        data.readAsDataURL(file);
        data.onload = async (dataReader) => {
          const formData = dataReader;
          //upload here afer confirmation
          const result = await MySwal.fire({
            title: 'Are you sure?',
            text: `Do you want to upload ${file.name}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, upload it!',
            cancelButtonText: 'No, cancel',
          });

          if (result.isConfirmed) {
            MySwal.showLoading();
            const post = await axios.post(`${url}/expense`, { file: formData.target.result }, {});
            console.log(post.data.data)
            setexpenses(post.data.data);
            MySwal.fire({
              title: <p>Item saved</p>,
              didOpen: () => { },
            });
          }

          // resolve(image_data);
        }
      } catch (error) {
        MySwal.fire({
          title: error.message ?? error
        });
      }
    })
  }


  const uploadReceipt = async () => {
    fileInputRef.current.click();
  }

  return (
    <div>
      <Header />
      <br />
      <br />
      <br />
      <div id="content" className="main-content" style={{ width: '120px !important' }}>
        <div className="layout-px-spacing">
          <div className="content-container">
            <div className="col-left layout-top-spacing">
              <div className="col-left-content">
                <div className="header-container">
                  <header className="header navbar navbar-expand-sm">
                    <div className="d-flex">
                      <a href="javascript:void(0);" className="sidebarCollapse" data-placement="bottom">
                        <div className="bt-menu-trigger">
                          <span />
                        </div>
                      </a>
                      <br />
                      <br />
                      <br />
                      <div className="page-header">
                        <div className="page-title">
                          <h6 style={{ marginLeft: '0px', fontWeight: 'bold' }}>Reports</h6>
                        </div>
                      </div>
                    </div>
                  </header>
                </div>
                <div className="admin-data-content layout-top-spacing mywidth" style={{ marginLeft: '30px' }}>
                  <a href="/reports" className="btn btn-success">Monthly Summary</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a href="/userlocation" className="btn btn-success">Users Per Location</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a href="/usermonth" className="btn btn-success">Users Per month</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a href="/expenses" className="btn btn-primary">Expenses</a>

                  <div className="row mb-row">
                    <div className="admin-data-content layout-top-spacing">
                      <div className="row">
                        <div className="col-sm-3">
                          <div className="form-group">
                            <label htmlFor="fullName">Select Date</label>
                            <input type="date" className="mb-4 form-control" id="date" placeholder="Date" onChange={handleChange} />
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group">
                            <label htmlFor="fullName">Enter Amount</label>
                            <input type='text' className="mb-4 form-control" id="amount" placeholder="Amount" onChange={handleChange} />
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group">
                            <label htmlFor="fullName">Total users</label>
                            <input type='number' className="mb-4 form-control" id="totalUsers" placeholder="Total users" onChange={handleChange} />
                          </div>
                        </div>
                      </div>
                      <div className="flex">
                        <button className='btn btn-danger mr' onClick={update}>Update Expenses</button>

                        <button className='btn btn-primary' onClick={uploadReceipt}>Upload Receipt</button>

                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                          onChange={processSingleImage}
                          accept='.jpg, .png, .jpeg'
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-12 col-lg-12 col-sm-12 mg-t" >
                    <h2 style={{ marginLeft: '-18px', fontWeight: 'bold' }}>Expenses</h2>
                    <div className="widget-content widget-content-area br-6">
                      <table id="html5-extension" className="table table-hover non-hover" style={{ width: '100%', marginLeft: '-40px' }}>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Amount Spent (CAD$)</th>
                            <th>Total User Count</th>
                            <th>File</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            expenses.length == 0 ? <>
                              No data available
                            </> : <>
                              {
                                expenses.map((res) => {
                                  return (
                                    <tr>

                                      <td>{formatDate(res.date)}</td>
                                      <td>{res.amount}</td>
                                      <td>{res.totalUsers}</td>
                                      <td>{
                                        res.uploadUrl != null ?
                                          <a href={uploadUrl + res.uploadUrl} target='_blank'>View item</a>
                                          :
                                          <></>
                                      }</td>
                                    </tr>
                                  )
                                })
                              }
                            </>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
        {/* </div> */}
        {/* </div>
        </div> */}
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>

  );

};

export default Expenses;
